// IndicatorsProvider.ts
import React, { useState, useEffect, ReactNode, FC } from 'react';
import { IndicatorsContext, Indicator } from './IndicatorsContext';

const IndicatorsProvider: FC<{ children: ReactNode }> = (props) => {

  const [indicators, setIndicators] = useState<Indicator[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchIndicators = async (targetId: number) => {
    setLoading(true);
    try {
      const response = await fetch(`https://dashboard.sdg.unkt.org/index.php/wp-json/custom/v1/indicators?target_id=${targetId}`);
      const data = await response.json();
      setIndicators(data);
    } catch (error) {
      console.error('Error fetching indicators:', error);
    } finally {
      setLoading(false);
    }
  };

const fetchAllIndicators = async (goalIds: number[]) => {
    setLoading(true);
    try {
        const allIndicators: { [goalId: number]: Indicator[] } = {};

        // Fetch indicators for each goalId
        await Promise.all(goalIds.map(async (goalId) => {
            const response = await fetch(`https://dashboard.sdg.unkt.org/index.php/wp-json/custom/v1/goal_indicators?goal_id=${goalId}`);
            const data = await response.json();
            
            const modifiedData = data.map((item: Indicator) => ({
                ...item, // Spread the existing properties
                goal_id: goalId // Modify the goal_id property
            }));
            // Append the fetched indicators to the allIndicators object
            allIndicators[goalId] = modifiedData;
        }));

        const final = Object.values(allIndicators).flat()
        console.log('final : ', final)
        setIndicators(final); // Flatten the array of indicators
    } catch (error) {
        console.error('Error fetching indicators:', error);
    } finally {
        setLoading(false);
    }
};



  return React.createElement(
    IndicatorsContext.Provider,
    { value: { indicators, loading, fetchIndicators, fetchAllIndicators } },
    props.children
  );
};

export { IndicatorsProvider };
