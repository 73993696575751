// GoalGrid.js

import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Card, Container, Row, Col } from 'react-bootstrap';  
import { GoalsContext } from '../State/GoalsContext'; // Import GoalsContext
import './GoalGrid.css';

const AlignmentPage = () => {

  const { goals, loading, setSelectedGoal } = useContext(GoalsContext);

  if (loading) return <p>Loading...</p>;

  const handleGoalClick = (goal) => {
    setSelectedGoal(goal);
  };

  return (
    <Container>
      <Row>
        <Row className="sdg-alignment-title" >          
          <h3>Alignment with SDG</h3>
        </Row>
        <Row className='sdg-alignment-subtitle' >          
          <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc iaculis diam eget velit tristique, ut feugiat dolor porttitor.</p>
        </Row>
      </Row>
      <div className="alignment-goal-grid"> {/* Replace 'div' with your container element */}
        {goals.map(goal => (
          <div key={goal.id} className="alignment-goal-item">
            

            {/* <Link to={`/goal/${goal.id}`} state={{ goal }}> */}
              {/* <img src={`/sdg-icons/${goal.icon}`} alt={goal.title.rendered} className="goal-icon" /> */}
              {/* <img src={ goal.featured_image_url } alt={goal.title.rendered} className="alignment-goal-icon" />
              {goal.acf.alignment_with_sdg_data.title}
              {goal.acf.alignment_with_sdg_data.description}
              {goal.acf.alignment_with_sdg_data.status} */}
          </div>
        ))}
      </div>
    </Container>
  );
};

export default AlignmentPage;
