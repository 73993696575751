import React, { useState, useEffect, useContext } from 'react';
import { Row } from 'react-bootstrap';
import IndicatorsAccordion from './IndicatorsAccordion'; // Adjust the import based on your file structure

import { IndicatorsContext } from '../State/IndicatorsContext'; // Import GoalsContext
import Skeleton from '@mui/material/Skeleton';
import '../index.css'; // Import your CSS file


const IndicatorDetails = ({ selectedTarget, selectedTargetId }) => {
  const { indicators, loading, fetchIndicators } = useContext(IndicatorsContext);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    if (selectedTargetId !== null) {      
      fetchIndicators(selectedTargetId);
    }
    

  }, [selectedTargetId, selectedTarget]);

  if (loading) return <div >
      <Skeleton variant="text" width={'100%'} height={5} />
  </div>;



  return (
    <>
      {selectedTarget ? (
        <div>
          <h5 style={{ paddingBottom: '15px' }}><strong>{selectedTarget.acf.index}</strong> {selectedTarget.acf.description}</h5>
          {/* <Row> */}
            <p className='small-text'>Ratings provide a visual representation of Kosovos performance on the SDG.</p>
            <IndicatorsAccordion indicators={indicators} /> {/* Pass indicators here */}
          {/* </Row> */}
        </div>
      ) : (
        <div>
          <p className='help-text'>
            <i className="bi bi-bar-chart-line-fill" style={{ fontSize: '20px', color: '#f7931a', paddingRight: '10px' }}></i>
            Pleae select a target to browse the
          </p>
        </div>
      )}
    </>
  );
};

export default IndicatorDetails;
