import React, { createContext } from 'react';

// Define the Target type
export interface Target {
  id: number;
  acf: {
    status: string
  }
  // Add other properties as needed
}

// Define the context type
interface TargetsContextType {
  targets: Target[];
  loading: boolean;
  fetchTargets: (goalId: number) => Promise<void>;
}

// Create and export the context
const TargetsContext = createContext<TargetsContextType | undefined>(undefined);

export default TargetsContext;
