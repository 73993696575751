import React from 'react';

const statusColors = {
    0: '#bdbdbd',
    1: '#d3302f',
    2: '#f67c01',
    3: '#fcc30c',
    4: '#43a047',
  };
  
  const statusText = {
    0: 'Information unavailable',
    1: 'Major challenges remain',
    2: 'Significant challenges remain',
    3: 'Challenges remain',
    4: 'SDG achieved',
  };
  

// 1. Export the circle only, with dynamic width and height
export const AlignmentWithSDGStatusCircleOnly = ({ alignmentStatus }) => {
    return (
    <div
        style={{
        backgroundColor: statusColors[alignmentStatus],
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        display: 'inline-block',
        }}
    ></div>
    );
};

// 2. Export the full component with circle and text
export const AlignmentWithSDGStatusWithText = ({ alignmentStatus }) => {
    return (
    <div className="goal-info-alignment-with-sdg">
        <p style={{ paddingRight: '15px' }}>
        <span
            style={{
            backgroundColor: statusColors[alignmentStatus],
            width: 16,
            height: 16,
            borderRadius: '50%',
            display: 'inline-block',
            float: 'left',
            }}
        ></span>
        </p>
        <p>{statusText[alignmentStatus]}</p>
    </div>
    );
};

export default AlignmentWithSDGStatusWithText;