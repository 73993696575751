import React, { useEffect, useState } from 'react';
import { Accordion, Card, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import ChartContainer from './ChartContainer'; 
import {StatusIndicatorWithColorText} from './StatusIndicator'; 
import { Button } from '@mui/material'; // Button from Material-UI
import FileDownloadOutlined from '@mui/icons-material/FileDownloadOutlined';


import html2canvas from 'html2canvas'; 
import { saveAs } from 'file-saver';


const statusColors = {
  low: 'red',
  mid: 'yellow',
  high: 'green',
};

const statusText = {
  low: 'Not Achieved',
  mid: 'In Progress',
  high: 'Achieved',
};

const IndicatorsAccordion = ({ targetId, indicators }) => {
  const { indicatorId } = useParams();
  const [chartRef, setChartRef] = useState(null);

  // Function to handle chart download
  const handleDownload = () => {
    if (chartRef) {
      html2canvas(chartRef).then(canvas => {
        canvas.toBlob(blob => {
          saveAs(blob, 'chart.png');
        });
      });
    }
  };

  return (
    <Row>    
       {/*Display section title if any items available  */}
      { indicators.length > 0  && (
        <div className='section-title'>
          <h6>Indicators</h6>
        </div>
      )}
      <Accordion defaultActiveKey={indicatorId ? indicatorId.toString() : '0'}>
        {indicators.map(indicator => (
          <div key={indicator.id} className='accordion-item-container'>
            <Accordion.Item eventKey={indicator.id.toString()}>
              <Accordion.Header>
                <p><strong>{indicator.acf.index }</strong> {indicator.acf.description}</p>
              </Accordion.Header>
              <Accordion.Body>
                <h6>Description</h6>
                <p>{indicator.acf.description}</p>
                <hr />
                <h6>Long-Term Objective</h6>
                <p>The long-term objective for this indicator is <strong>{indicator.acf.long_term_objective } {indicator.acf.graph_data_indicators.suffix}</strong>.</p>
                <hr />
                <h6>Progress</h6>
                <StatusIndicatorWithColorText status={indicator.acf.status}></StatusIndicatorWithColorText>
                <hr />
                <h6>Data</h6>
                <ChartContainer indicator={indicator} chartType={indicator.chartType} chartData={indicator.chartData} />
                <hr />
                <h6>Reference</h6>
                <p>{indicator.acf.reference_field}</p>
                <hr />
                <h6>Source</h6>
                <p><a className="link-offset-2 link-underline link-underline-opacity-25" href='' target='_blank' >{indicator.acf.source}</a></p>
                <hr />
                <h6>Download</h6>
                <div className='graph-buttons'>
                  <Button startIcon={<FileDownloadOutlined />} variant="outlined" color="secondary" size="small" onClick={handleDownload}>
                    Download Data
                  </Button>
                  <Button startIcon={<FileDownloadOutlined />} variant="outlined" color="secondary" size="small" onClick={handleDownload}>
                    Download Graph As Image
                  </Button>
                </div>

              </Accordion.Body>
            </Accordion.Item>
          </div>
        ))}
      </Accordion>
    </Row>
  );
};



export default IndicatorsAccordion;
