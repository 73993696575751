import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { AlignmentWithSDGStatusWithText }  from './AlignmentWithSDGStatus';
import { StatusIndicatorWithText } from './StatusIndicator';




const GoalInfo = ({ goal }) => {
  return (
    <>
    <Row className="sdg-details-left-container">
      <Col xs={4} className="targets-column-image col-4">
        <img src={ goal.featured_image_url } alt={goal.title.rendered} className="goal-icon" />
      </Col>
      <Col xs={8} className="targets-column-image col-8">
        <h3 className='goal-details-title'>{goal.acf.name}</h3>
      </Col>
    </Row>
    <Row style={{paddingTop: '25px' }}><p>{goal.acf.description}</p></Row>
    <Row className="sdg-details-left-container" style={{marginTop: '15px',  paddingBottom: '15px'}}>
      <div className='section-title'>
        <h6>Dashboard Goal Status</h6>
      </div>
      <AlignmentWithSDGStatusWithText alignmentStatus={goal.acf.alignment_with_sdg_data.status} ></AlignmentWithSDGStatusWithText>
      <StatusIndicatorWithText status={goal.acf.status}></StatusIndicatorWithText>

    </Row>
    {/* <div className='line'> </div> */}
    </>
  );
};

export default GoalInfo;
