// IndicatorsContext.ts
import React from 'react';

export interface Indicator {
  id: number;
  title: string;
  description: string;
  acf: {
    index: string,
    long_term_objective: string,
    graph_data_indicators: {
        data_display_type: string,
        whole_data: string,
        suffix: string,
        plain_text: {
            plain_text_label: string
            value: string
        },
        pie_chart: []
    },
    reference_field: string
  };
  goal_id: string;
  // Add other fields as necessary
}

interface IndicatorsContextType {
  indicators: Indicator[];
  loading: boolean;
  fetchIndicators: (targetId: number) => void;
  fetchAllIndicators: (goalId: number[]) => void,

}

const defaultContextValue: IndicatorsContextType = {
    indicators: [],
    loading: true,
    fetchIndicators: () => {},
    fetchAllIndicators: () => {},
};

const IndicatorsContext = React.createContext<IndicatorsContextType>(defaultContextValue);

export { IndicatorsContext };