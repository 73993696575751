import React, { useState, useEffect, ReactNode, FC } from 'react';
import { GoalsContext, Goal } from './GoalsContext';

const GoalsProvider: FC<{ children: ReactNode }> = (props) => {
  const [goals, setGoals] = useState<Goal[]>([]);
  // const [goal, setGoal] = useState<Goal | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedGoal, setSelectedGoal] = useState<Goal | null>(null);


  useEffect(() => {
    const fetchGoals = async () => {
      try {
        const response = await fetch('https://dashboard.sdg.unkt.org/index.php/wp-json/wp/v2/goals?per_page=20&orderby=date&order=asc'); 
        const data = await response.json();
        console.log(response)
        setGoals(data);
      } catch (error) {
        console.error('Error fetching goals:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchGoals();
  }, []);

  const fetchGoal = async (id: number) => {
    try {
      const response = await fetch(`https://dashboard.sdg.unkt.org/index.php/wp-json/wp/v2/goals/${id}`); 
      const data = await response.json();
      console.log(response)
      setSelectedGoal(data);
    } catch (error) {
      console.error('Error fetching goals:', error);
    } finally {
      setLoading(false);
    }
  };

  // Create the provider element using React.createElement
  return React.createElement(
    GoalsContext.Provider,
    { value: { goals, loading, selectedGoal, fetchGoal, setSelectedGoal  } },
    props.children
  );
};

export { GoalsProvider };
