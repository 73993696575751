// GoalsContext.ts
import React from 'react';

// Define the shape of a single goal
export interface Goal {
  id: number;
  title: {
    rendered: string
  }
  acf: { 
    name: string
    description: string
    status: string
    alignment_with_sdg_data: {
      status: string
    }
  };
  color: string;
  icon: string;
  featured_image_url: string;
}

// Define the shape of the context value
interface GoalsContextType {
    goals: Goal[];
    loading: boolean;
    selectedGoal: Goal | null;
    fetchGoal: (id: number) => Promise<void>;
    setSelectedGoal: (goal: Goal) => void;
}

const defaultContextValue: GoalsContextType = {
    goals: [],
    loading: true,
    selectedGoal: null,
    fetchGoal: async (id: number) => {},
    setSelectedGoal: () => {},
};
  
// Create the context
const GoalsContext = React.createContext<GoalsContextType>(defaultContextValue);

export { GoalsContext };