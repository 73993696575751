import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { AlignmentWithSDGStatusWithText }  from './AlignmentWithSDGStatus';
import { StatusIndicatorWithText } from './StatusIndicator';
import './LegendHorizontalList.css'; // Import the CSS file


const LegendTrendHorizontalList = () => {
    const numbers = [4, 3, 2, 1, 0]; // Your array of numbers
  
    return (
        <div className="legend-grid-container">
        {numbers.map((number, index) => (
          <div key={index} className="legend-grid-column">
            <div className="status-item">
              <StatusIndicatorWithText status={number} />
            </div>
            <div className="alignment-item">
              <AlignmentWithSDGStatusWithText alignmentStatus={number} />
            </div>
          </div>
        ))}
      </div>  
    );
    
};
  

const LegendStatusHorizontalList = () => {
    const numbers = [0, 1, 2, 3, 4]; // Your array of numbers

    return (
    <Col className='legend'> 
        <Row className='justify-content-start'>
        <ul className='horizontal-list'>
            {numbers.map((number, index) => (
            <li key={index} className='list-item'>
                <AlignmentWithSDGStatusWithText alignmentStatus={number} ></AlignmentWithSDGStatusWithText>
            </li>
            ))}
        </ul>
        </Row>
    </Col>
    );
};

export { LegendTrendHorizontalList, LegendStatusHorizontalList } ;