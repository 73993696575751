import React from 'react';
import { Routes, Route } from 'react-router-dom';
import GoalGrid from './Components/GoalGrid';
import GoalDetail from './Components/GoalDetail';
import 'bootstrap/dist/css/bootstrap.min.css';
import Header from './Components/Header';
// import Footer from './components/Footer';
import { GoalsProvider } from './State/GoalsProvider'; // Import the GoalsProvider
import TargetsProvider  from './State/TargetsProvider'; // Import the GoalsProvider
import { IndicatorsProvider }  from './State/IndicatorsProvider'; // Import the GoalsProvider
import AlignmentPage from './Components/AlignmentPage';

function App() {
  return (
    <GoalsProvider>
      <TargetsProvider>
        <IndicatorsProvider>
          <div className="App">
            <Header />
            <main className="flex-grow-1">
              <Routes>
                <Route path="/" element={<GoalGrid />} />
                <Route path="/goal/:goalId/*" element={<GoalDetail />} />
                <Route path="/goal/:goalId/:targetId/*" element={<GoalDetail />} />
                
                <Route path="/alignment" element={<AlignmentPage />} />

              </Routes>
            </main>
            {/* <Footer /> */}
          </div>
        </IndicatorsProvider>
      </TargetsProvider>
    </GoalsProvider>
  );
}


export default App;
