import React, { useEffect, useState, useRef } from 'react';
import { LineChart, BarChart, PieChart } from '@mui/x-charts'; // Import the chart components
import { Grid } from '@mui/material'; // Import Grid and Button from Material-UI
import { Row } from 'react-bootstrap';

const DataVisualizationType = Object.freeze({
  BAR_CHART: 'bar_chart',
  LINE_CHART: 'line_chart',
  PIE_CHART: 'pie_chart',
  PLAIN_TEXT: 'plain_text',
  NONE: 'none'
});

const serieColors = [
  '#4e79a7',
  '#f28e2c',
  '#59a14f',
  '#af7aa1',
  '#76b7b2',
  '#edc949',
  '#ff9da7',
  '#9c755f',
];

const ChartComponent = ({ indicator, chartType, chartData, setChartRef }) => {
  const [data, setData] = useState([]);
  const [options, setOptions] = useState({});
  const [dataType, setDataType] = useState(DataVisualizationType.NONE);

  const [loading, setLoading] = useState(true);
  const [highestValue, setHighestValue] = useState(0);
  const [seriesVisibility, setSeriesVisibility] = useState([]);


  // const [error, setError] = useState(null);
  const chartRef = useRef(null); // Create a ref for the chart container

  useEffect(() => {
    // For printing the canvas
    if (setChartRef) setChartRef(chartRef.current); // Set the chart ref in parent

    
    if (indicator.acf.graph_data_indicators.data_display_type === 'line-graph-bar-chart') {
      
      // Line or Bar Chart 
      let mappedObject = JSON.parse(indicator.acf.graph_data_indicators.whole_data);
      
      if (mappedObject.isBarChart) {
        setDataType(DataVisualizationType.BAR_CHART)
      } else {
        setDataType(DataVisualizationType.LINE_CHART)
      }

      // Set line or bar chart options
      const labelsObject = mappedObject.columns.find(column => column.label === "Year");
      const labelsArray = labelsObject ? labelsObject.data : mappedObject.columns[0].data;

      const otherLabels = mappedObject.columns.filter(column => column.label !== "Year");
      // Initialize series visibility to true for all series if it's not already initialized
      if (seriesVisibility.length !== otherLabels.length) {
        setSeriesVisibility(new Array(otherLabels.length).fill(true));
      }

      let suffix = indicator.acf.graph_data_indicators.suffix

      let series = otherLabels.map((item, index) => ({
        label: item.label,
        type: mappedObject.isBarChart ? 'bar' : 'line',
        data: item.data,
        hidden: !seriesVisibility[index], // Use the visibility state
        color: serieColors[index],
        valueFormatter: (v, { dataIndex }) => {
          return `${v} ${suffix}.`
        },
  
      }))

      console.log("series.data : ", series[0].data);
      
      setOptions({
        xData: labelsArray,
        yData: series,
      })
    } else if (indicator.acf.graph_data_indicators.data_display_type === 'pie-chart') {

      // Set pie chart options
      let chartData = indicator.acf.graph_data_indicators.pie_chart;
      setDataType(DataVisualizationType.PIE_CHART)
      setOptions(chartData)      
    } else if (indicator.acf.graph_data_indicators.data_display_type === 'plain-text') {
      let label = indicator.acf.graph_data_indicators.plain_text.plain_text_label
      let value = indicator.acf.graph_data_indicators.plain_text.value
      
      setDataType(DataVisualizationType.PLAIN_TEXT)
      setOptions({
        xData: label,
        yData: value,
      })
    } else {
      setDataType(DataVisualizationType.NONE)
    }
    setLoading(false)

  }, [indicator, seriesVisibility, setChartRef]);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (options.length === 0) {
    return <p>No chart data available.</p>;
  }

  const toggleSeriesVisibility = (index) => {
    setSeriesVisibility(prev => {
      const newVisibility = [...prev];
      newVisibility[index] = !newVisibility[index];
      return newVisibility;
    });
  };

  const targetValue = 10;

  return (
    <>
      <Grid container spacing={2}>
        
        <Grid item xs={12}>
        
          <div ref={chartRef} style={{position: 'relative'}}>
            {dataType === 'line_chart' && (
              
              <LineChart
                slotProps={{ legend: { hidden: true } }}
                height={300}
                // width={300}
                xAxis={[{ data: options.xData, scaleType: 'point' }]}
                series={ options.yData.filter(series => !series.hidden)} // Filter out hidden series 
                
              />
            )}
            {dataType === 'bar_chart' && (
              <BarChart
                slotProps={{ legend: { hidden: true } }}
                height={300}
                xAxis={[{ data: options.xData, scaleType: 'band' }]}
                series={ options.yData.filter(series => !series.hidden)}
              />
            )}
            {dataType === 'pie_chart' && (
              <PieChart
                series={[ { data: options } ]}
                height={300}
              />
            )}

            {dataType === 'plain_text' && (
              <div className='row-plaint-text'>
                <div className='chart-plain-text-label'>
                  { options.xData }
                </div>
                <div className='chart-plain-text-value'>
                  {options.yData}
                </div>
              </div>
            )}

            {/* Show labels for hiding and showing series */}
            <div className='seriesHideShowContainer'>
              {(dataType === 'line_chart' || dataType === 'bar_chart') && options.yData && options.yData.length > 0 && options.yData.map((item, index) => (
                <div key={item.label} className="displaySeriesContainer" style={{color: item.color}}>
                    <label className="container">
                      <input 
                        type="checkbox"
                        checked={seriesVisibility[index]}
                        onChange={() => toggleSeriesVisibility(index)}
                      />
                      <span class="checkmark"></span>
                    </label>
                    <p onClick={() => toggleSeriesVisibility(index)}>{item.label}</p>
                </div>
              ))}
            </div>
            
          </div>
        </Grid>
      </Grid>
      
    </>
  );
};

export default ChartComponent;
  