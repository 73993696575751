import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { StatusIndicator } from './StatusIndicator';

const statusColors = {
  low: 'red',
  mid: 'yellow',
  high: 'green',
};

const TargetsList = ({ targets, goalId, activeTargetId, onTargetSelect }) => {
  const { targetId } = useParams(); // Get targetId from URL parameters

  const handleTargetClick = (targetId) => {
    onTargetSelect(targetId); // Update selected target ID in parent component
  };

  return (
    <ListGroup>
      {targets.map(target => (
        
        <Link
          key={target.id}
          to={`/goal/${goalId}/${target.id}`} // Link to target with goalId and targetId in URL
          className={`list-group-item list-group-item-action ${target.id.toString() === targetId ? 'active' : ''}`}
          onClick={() => handleTargetClick(target.id)} // Call handleTargetClick when target is clicked
        >
          
          <StatusIndicator status={target.acf.status}></StatusIndicator>

          <strong>{target.acf.index}</strong> {target.acf.description}
        </Link>
      ))}
    </ListGroup>
  );
};

export default TargetsList;


