import React from 'react';


const statusColors = {
  0: '#bdbdbd',
  1: '#d3302f',
  2: '#f67c01',
  3: '#fcc30c',
  4: '#43a047',
};

const statusText = {
  0: 'Trend information unavailable',
  1: 'Decreasing',
  2: 'Stagnating',
  3: 'Moderately improving',
  4: 'On track or maintaining SDG achievement',
};


const size = 14;

const getStatusIcon = (status, fontSize = size) => {
  switch (status) {
    case '0':
      return (
        <span className='status-icon' style={{ color: statusColors[status], fontSize: fontSize, fontWeight: 'bold', width:'16px', textAlign:'center', display: 'inline-block' }}>
          - {/* Unicode character for down arrow */}
        </span>
      );
    case '1':
      return (
        <span className='status-icon' style={{ color: statusColors[status], fontSize: fontSize, fontWeight: 'bold', display: 'inline-block' }}>
        &#8595; {/* Unicode character for diagonal arrow */}
        </span>
      );
    case '2':
      return (
        <span className='status-icon' style={{ color: statusColors[status], fontSize: fontSize, fontWeight: 'bold', display: 'inline-block' }}>
          &#8594; {/* Unicode character for up arrow */}
        </span>
      );
    case '3':
    return (
      <span className='status-icon' style={{ color: statusColors[status], fontSize: fontSize, fontWeight: 'bold', display: 'inline-block' }}>
        &#8599; {/* Unicode character for up arrow */}
      </span>
    );
    case '4':
      return (
        <span className='status-icon' style={{ color: statusColors[status], fontSize: fontSize, fontWeight: 'bold', display: 'inline-block' }}>
          &#8593; {/* Unicode character for up arrow */}
        </span>
      );
    default:
      return null;
  }
};



const StatusIndicator = ({ status, fontSize = size }) => (
  <div className='status-indicator' style={{ display: 'inline-block' }}>
      {getStatusIcon(status, fontSize)}
  </div>
);


const StatusIndicatorWithColorText = ({ status }) => (
  <div style={{ width: '100%', display: 'inline-block', paddingRight: '10px', marginTop: '1px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>

          <p style={{ float: 'left', marginBottom: '0px' }}>On track or maintaining SDG achievement</p>
          <p style={{ paddingLeft: '5px', paddingRight: '5px', marginBottom: '0px', color: statusColors[status] }}>
              {statusText[status]}
              
          </p>
          {getStatusIcon(status)}
    </div>
  </div>
);

const StatusIndicatorWithText = ({ status }) => (
  <div style={{ width: '100%', display: 'inline-block', paddingRight: '10px', marginTop: '1px' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
          {getStatusIcon(String(status), 20)}
          <p style={{ paddingLeft: '15px', paddingRight: '5px', marginBottom: '0px'}}>
              {statusText[status]}
          </p>
          
    </div>
  </div>
);

  
export { StatusIndicator, StatusIndicatorWithText, StatusIndicatorWithColorText };
