import React, { useState, useEffect, ReactNode, FunctionComponent } from 'react';
import TargetsContext, { Target } from './TargetsContext';

// Define the TargetsProvider component
const TargetsProvider: FunctionComponent<{ children: ReactNode }> = (props) => {
  const [targets, setTargets] = useState<Target[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const fetchTargets = async (goalId: number) => {
    setLoading(true);
    try {
      const response = await fetch(`https://dashboard.sdg.unkt.org/index.php/wp-json/custom/v1/targets?goal_id=${goalId}`);
      const data = await response.json();
      console.log("THESE ARE THE TARGETS: ");
      
      setTargets(data);
    } catch (error) {
      console.error('Error fetching targets:', error);
    } finally {
      setLoading(false);
    }
  };

  // Provide the context value
  const contextValue = { targets, loading, fetchTargets };

  // Use React.createElement to create the provider
  return React.createElement(TargetsContext.Provider, { value: contextValue }, props.children);
};

export default TargetsProvider;
