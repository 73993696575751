import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Modal, Button } from 'react-bootstrap';
import { useMediaQuery } from 'react-responsive';
import 'bootstrap-icons/font/bootstrap-icons.css';
import GoalInfo from './GoalInfo';
import TargetsList from './TargetsList.js';
import IndicatorDetails from './IndicatorDetails';
import { GoalsContext } from '../State/GoalsContext'; // Import GoalsContext
import TargetsContext from '../State/TargetsContext'; // Import TargetsContext
import Skeleton from '@mui/material/Skeleton';

const GoalDetail = () => {

  const { selectedGoal, fetchGoal, loading: goalLoading } = useContext(GoalsContext);
  const { targets, loading: targetsLoading, fetchTargets } = useContext(TargetsContext);

  const { goalId, targetId } = useParams();
  const [selectedTargetId, setSelectedTargetId] = useState(targetId || null);
  const [selectedTarget, setSelectedTarget] = useState(targets.find(t => t.id === parseInt(targetId)) || null);
  const [showSheet, setShowSheet] = useState(false);

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  // handle selected target
  useEffect(() => {
    if (targetId) {
      setSelectedTargetId(targetId);
      setSelectedTarget(targets.find(t => t.id === parseInt(targetId)));
    }
  }, [targetId, targets]);

  // show for mobile 
  useEffect(() => {
    if (isMobile && selectedTargetId) {
      setShowSheet(true);
    }
  }, [isMobile, selectedTargetId]);

  const handleCloseSheet = () => setShowSheet(false);

  // Check for selected goal 
  useEffect(() => {

    if (!selectedGoal || selectedGoal.id !== parseInt(goalId)) {
      fetchGoal(parseInt(goalId));
    }

    // fetch targets
    if (selectedGoal) {
      fetchTargets(selectedGoal.id);
    }

  }, [goalId, selectedGoal]);
  
  if (goalLoading || targetsLoading ) return <div >
        <Skeleton variant="text" width={'100%'} height={5} />
    </div>;

  if (!selectedGoal) return <p>Goal not found</p>;

  return (
    <div className='container-full'>
      <Container fluid className='goal-details-container'>
        <Row style={{ height: '91%' }}>
          <Col xs={12} md={4} className="targets-column">
            <GoalInfo goal={selectedGoal} />
            <Row className="targets-list">
            <div className='section-title'>
              <h6>Targets</h6>
            </div>
              <TargetsList
                targets={targets} // Pass targets here
                goalId={goalId}
                activeTargetId={selectedTargetId}
                onTargetSelect={setSelectedTargetId}
              />
            </Row>
          </Col>
          {!isMobile && (
            <Col xs={12} md={8} className='indicators-column'>
              <IndicatorDetails
                selectedTarget={selectedTarget}
                selectedTargetId={selectedTargetId}
              />
            </Col>
          )}
        </Row>

        {/* Bottom sheet for mobile view */}
        {isMobile && showSheet && (
          <div className="bottom-sheet">
            <div className="bottom-sheet-header">
              <Button variant="secondary" onClick={handleCloseSheet}>
                Close
              </Button>
            </div>
            <div className="bottom-sheet-content">
              <IndicatorDetails
                selectedTarget={selectedTarget}
                selectedTargetId={selectedTargetId}
              />
            </div>
          </div>
        )}
      </Container>
    </div>
  );
};

export default GoalDetail;
