import React from 'react';
import './GoalGrid.css'; // Assuming you have a CSS file for styles

const DataSquare = ({ percentage }) => {
  const rotation = (percentage / 100) * 360; // Calculate rotation based on percentage

  return (
    <div className="data-square">
      <div className="data-title">SDG Index Score</div>
      <div className="circle">
        <div className="circle-inner"></div>
        <div
          className="circle-overlay"
          style={{ transform: `rotate(${rotation}deg)` }} // Apply rotation
        ></div>
        <div className="percentage-text">{percentage}</div>
      </div>
    </div>
  );
};

export default DataSquare;
